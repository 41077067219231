'use client';
// @ts-ignore
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { ButtonOverride, Tag } from '@/shared/api/arena-data';
import { buildButtonCSSOverrides } from '@/shared/utils/utils';
import { useContext } from 'react';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import styles from './styles.module.css';
import { AnalyticsContext } from '@/components/Layout';
import { LocalizedLink } from '@/components/Link';
import classNames from 'classnames';

type Props = {
	title?: string;
	tags?: Tag[] | null;
	mobileCarousel?: boolean;
	overrides?: ButtonOverride[] | null;
};

export const Tags = (props: Props) => {
	const { isDesktop } = useDeviceDetector();
	const isDesktopDetected = isDesktop();
	const { AITracks } = useContext(AnalyticsContext);

	if (!props?.tags?.length) {
		return null;
	}

	function buildTag(index: number, tag: Tag) {
		const tagText = tag?.name?.slice(0, isDesktopDetected ? 22 : 20) || '';
		return (
			<LocalizedLink
				key={index}
				className={styles.tag}
				style={
					{
						'--image': `url(${tag?.image?.url})`,
						...styleOverrides,
					} as React.CSSProperties
				}
				href={`/tags/${tag.slug}`}
				onClick={() => {
					AITracks.tagButtonClick(tagText);
				}}
			>
				<span className={styles.name}>{tagText}</span>
			</LocalizedLink>
		);
	}

	const styleOverrides = buildButtonCSSOverrides(isDesktopDetected, props?.overrides);
	return (
		<div className={classNames(styles.tags, 'tags')}>
			{props?.title && <p className={styles.title}>{props?.title}</p>}
			{!isDesktopDetected && props?.mobileCarousel ? (
				<Splide
					options={{
						gap: '0.5rem',
						autoWidth: true,
						width: '100%',
						pagination: false,
						arrows: false,
						flickPower: 500,
						flickMaxPages: 3,
					}}
					hasTrack={false}
					className='carousel'
				>
					<SplideTrack>
						{props?.tags?.map((tag: any, index: number) => (
							<SplideSlide key={index} className='splide__slide'>
								{buildTag(index, tag)}
							</SplideSlide>
						))}
					</SplideTrack>
				</Splide>
			) : (
				<nav className={styles.list}>{props?.tags?.map((tag: any, index: number) => buildTag(index, tag))}</nav>
			)}
		</div>
	);
};
