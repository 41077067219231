import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { WEB_COMPONENTS_NAMES } from '@/shared/app';
import { CardAttributes } from '../carousel/model';
import { Badge } from '@/shared/api/arena-data';
import { ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]/types';
import { getValidBadgesBySlug } from '@/shared/utils/utils';
import { TGameData } from '@/root/app/[locale]/games/[slug]/model';

export type TCarouselRecentlyPlayed = {
	__component: WEB_COMPONENTS_NAMES.RecentlyPlayed;
	id: number;
	title: string;
	cardsType: 'small' | 'medium';
	placeholderText: string;
	analyticId: string;
};

export type TOverriddenSlideAttributes = {
	tagName: string;
	attributes: CardAttributes;
	handleClick?: (AITracks?: any) => void;
};

export const overrideSlideAttributes = (
	c: TGameData,
	arenaData: any,
	isMobile: boolean,
	AITracks: any,
	badgesList: Badge[],
	gameFeedData: ShortGameModelFromFeed[],
	size: 'small' | 'medium',
	carouselId?: string | null,
): TOverriddenSlideAttributes | null => {
	const attributesOverride = arenaData?.layout?.styleOverride?.standardCard || {};
	// attributes mapping
	const isGradientButton =
		attributesOverride?.hoverBlock?.overrides?.buttonBackground_end &&
		attributesOverride?.hoverBlock?.overrides?.buttonBackground_end !==
			attributesOverride?.hoverBlock?.overrides?.buttonBackground;
	const attributes: CardAttributes = {
		onMobile: isMobile,
		size: size,
		action_bar: 'false', // Acceptance criteria: Full background
		thumbnail_mode: 'false',
		title: c?.meta?.name || c?.name || '',
		background_color: 'transparent',
		background_image_desktop: c?.meta?.thumbs?.graphic_288x192 || '',
		background_image_mobile: c?.meta?.thumbs?.graphic_288x192 || '',
		badge: getValidBadgesBySlug(gameFeedData, c?.slug || '', badgesList ?? []),
		link_label: '',
		game_category: c?.meta?.categories?.[0] || '',
		game_alias: c?.meta?.alias || '',
		game_name: c?.name || '',
		game_ext_id: c?.id || 0,
		game_description: c?.meta?.description || '',
		game_slug: c?.slug || '',
		description: c?.meta?.description || '',
		shortDescription: c?.meta?.details || '',
		override_color_title: attributesOverride?.title || '',
		override_hover_block_toggled: attributesOverride?.hoverBlock?.type,
		override_hover_block_color_bcg: attributesOverride?.hoverBlock?.background,
		override_hover_block_color_text: attributesOverride?.hoverBlock?.color,
		override_hover_block_button_text: attributesOverride?.hoverBlock?.buttonText || 'Play',
		override_hover_block_button_text_color: attributesOverride?.hoverBlock?.buttonTextColor,
		override_hover_block_button_background_color: attributesOverride?.hoverBlock?.buttonBackground,
		override_hover_block_button_background_color_end: attributesOverride?.hoverBlock?.buttonBackground_end,
		override_hover_block_button_border_color: attributesOverride?.hoverBlock?.buttonBorderColor,
		override_button_normal_should_use_border: attributesOverride?.hoverBlock?.should_button_use_border !== false,
		...(!isGradientButton
			? {}
			: {
					override_button_hover_should_use_border: true,
					override_button_hover_border: attributesOverride?.hoverBlock?.overrides?.buttonBorderColor,
					override_button_active_should_use_border: true,
					override_button_active_border: attributesOverride?.hoverBlock?.overrides?.buttonBorderColor,
				}),
		override_is_hover_block: true,
	};
	const tagName = 'ark-ui-block-card-standard';

	//For Analytics - card click event
	const handleClick = (): void =>
		AITracks?.genericTrack?.({
			eventName: 'gameCard',
			isNonInteraction: false,
			eventAction: AnalyticsEventAction.CLICK,
			customDimensions: {
				cardType: 'StandardCard',
				cardTitle: c?.meta?.name ?? c?.name ?? '',
				cardImageURL: c?.meta?.thumbs?.graphic_288x152,
				carouselID: carouselId ? carouselId + `-${c?.slug}` : '',
			},
		}) as void;

	if (!tagName) {
		return null;
	}
	return { tagName, attributes, handleClick };
};
