import React from 'react';

const ClockIcon = () => {
	return (
		<svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M49.1832 29.25L44.8512 24.9167L40.5166 29.25M45.5 26C45.5 36.7696 36.7696 45.5 26 45.5C15.2304 45.5 6.5 36.7696 6.5 26C6.5 15.2304 15.2304 6.5 26 6.5C33.1542 6.5 39.4085 10.3526 42.8014 16.0964M26 15.1667V26L32.5 30.3333'
				stroke='#A5A4A4'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ClockIcon;
