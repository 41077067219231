'use client';
import { useEffect, useRef, useMemo } from 'react';
import { TIframeBlockInfo } from './model';
import { useSearchParams } from 'next/navigation';
import styles from './styles.module.css';

const IframeBlock = (props: TIframeBlockInfo) => {
	const searchParams = useSearchParams();
	const sectionRef = useRef<HTMLDivElement>(null);

	const iframeUrl = useMemo(() => {
		if (!props.URL) return '';
		if (!props.shouldUseQueryParams) return props.URL;

		const url = new URL(props.URL);
		// if url form server already contains params they take precedence
		const existingParams = new Set(url.searchParams.keys());

		searchParams.forEach((value, key) => {
			if (!existingParams.has(key)) {
				url.searchParams.append(key, value);
			}
		});

		return url.toString();
	}, [props.URL, props.shouldUseQueryParams, searchParams]);

	useEffect(() => {
		if (!sectionRef.current) return;
		const wrapper = sectionRef.current;
		if (props.width) {
			wrapper.style.maxWidth = props.width + 'px';
		}
		if (props.height) {
			wrapper.style.height = props.height + 'px';
		}
	}, [props.width, props.height]);

	if (!props.URL) return null;
	return (
		<section className={styles.iframeBlockSection} ref={sectionRef}>
			<iframe src={iframeUrl} width={'100%'} height={'100%'} title={props.title ?? 'external iframe block'} />
		</section>
	);
};

export default IframeBlock;
