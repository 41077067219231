'use client';
import React, { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import { Splide, SplideProps, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { UIKIT_CONSTS } from '@/components/UiKit/UiKitConnector';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import { LocalizedLink } from '@/components/Link';
import {
	CardPromoAttributes,
	overrideSlideAttributes,
	PromoSlide,
	TCarouselPromo,
} from '@/features/carouselPromo/model';
import ButtonPlay from './button_play.svg';
import ButtonPause from './button_pause.svg';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { Promo } from './model';
import RecentlyPlayed from '@/components/RecentlyPlayed';
import { SlideComponent } from '@splidejs/splide';
import { LocalStorageService } from '@/shared/utils/local-storage';
import { AnalyticsContext } from '@/components/Layout';
import { PromoCard } from '@/components/Cards/PromoCard';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';

// CONFIG:
const SLIDER_OPTIONS: SplideProps['options'] = {
	loop: true,
	type: 'slide',
	autoPlay: true,
	pauseOnHover: true,
	pauseOnFocus: true,
	focus: 0,
	omitEnd: true,
	autoWidth: true,
	lazyLoad: true,
	focusableNodes: 'a', // remove unecessary focus by enabling the focus only for a tags on the promo
};

// COMPONENT
export const Slider = (props: TCarouselPromo) => {
	const arenaData = useUnit($arenaDataStore);
	const slidesList: PromoSlide[] = props?.slides || [];
	const [isSliderScrolled, setIsSliderScrolled] = useState(false);
	const splideRef = useRef<Splide>(null);
	const [autoplaySpeedTime, setAutoplaySpeedTime] = useState(7000);
	const isLoop = props.infiniteLoop !== false;
	const onePlusSlides = slidesList.length > 1;
	const { isMobile, isDesktop } = useDeviceDetector();
	const { AITracks } = useContext(AnalyticsContext);
	const carouselId = props?.analyticId;

	function sendAnalytics(eventName: string) {
		AITracks.genericTrack({
			event: eventName,
			isNonInteraction: false,
			eventAction: AnalyticsEventAction.CLICK,
		});
	}

	// Setup splide
	useEffect(() => {
		const splide = splideRef?.current;
		const splideEl = splide?.splideRef?.current;
		const splideObj = splide?.splide;
		if (!splideEl) {
			return;
		}

		// Autoplay
		setAutoplaySpeedTime(
			// as on ark.com
			typeof window !== 'undefined' && window?.innerWidth && window.innerWidth <= 768
				? 5000 // for mobile
				: 7000, // for desktop
		);

		mountAutoplayOnClickedNav();

		// ??
		splideObj?.on?.('dragged', (index: number) => {
			setIsSliderScrolled(index !== 0);
			splideObj?.Components?.Autoplay?.pause();
		});

		// Preventing slide uncontrolled scroll into view on focus
		splideObj?.Components?.Slides?.forEach((slide: any) => {
			const slideIndex = slide?.index;
			const slideEls = [slide?.slide, ...Array.from(slide?.slide?.querySelectorAll?.('button, a') || [])];
			slideEls.forEach((el: any) => {
				el?.addEventListener?.('focus', () => {
					splideObj?.Components?.Controller?.go?.(slideIndex);
				});
			});
		});

		// Handling setting on paused when no-loop carousel ends
		if (!isLoop) {
			splideObj?.on?.('active', (slide: SlideComponent) => {
				if (slide?.index === slidesList.length - 1) {
					splideObj?.Components?.Autoplay?.pause();
				}
			});
		}

		// Initialize analytics on See all
		const carouselTitleLink = splideEl?.parentNode?.querySelector?.('.carousel_title > a');
		if (!carouselTitleLink) {
			return;
		}
		const linkAnalytics = () => sendAnalytics('seeAllButton');
		carouselTitleLink.addEventListener('click', linkAnalytics);

		// Clean up
		return () => {
			carouselTitleLink.removeEventListener('click', linkAnalytics);
			mountAutoplayOnClickedNav(true);
		};

		function mountAutoplayOnClickedNav(remove = false) {
			Array.from(splideEl?.querySelectorAll?.('.splide__pagination__page') || [])?.forEach((stripe: any) => {
				!remove && stripe.addEventListener('click', handleAutoplayOnClickedNav);
				remove && stripe.removeEventListener('click', handleAutoplayOnClickedNav);
			});
			function handleAutoplayOnClickedNav(ev: Event) {
				splideObj?.Components?.Autoplay?.pause();
				(ev?.currentTarget as HTMLElement)?.blur?.();
			}
		}
	}, [splideRef]);

	// NavStripesFx(splideRef);

	const renderSlide = (promo: PromoSlide, cardIndex: number) => {
		const overridenSlide = overrideSlideAttributes(promo, cardIndex, arenaData, isMobile(), AITracks, carouselId);
		if (!overridenSlide) {
			return null;
		}

		const { attributes, handleClick } = overridenSlide;

		return (
			<SplideSlide key={cardIndex} className='splide__slide'>
				<SetPromoCard card={promo} attributes={attributes} handleClick={handleClick} />
			</SplideSlide>
		);
	};

	// set render of slides
	const slides = useMemo(() => slidesList.map(renderSlide), [sendAnalytics]);

	const mergedOptions: SplideProps['options'] = {
		...SLIDER_OPTIONS,
		type: isLoop ? 'loop' : 'slide',
		pagination: onePlusSlides,
		autoplay: true,
		interval: autoplaySpeedTime,
		arrows: false,
		pauseOnHover: true,
	};

	const desktop = isDesktop();

	const [isDesktopSize, setIsDesktopSize] = useState<boolean>(desktop);
	useEffect(() => {
		setIsDesktopSize(
			Boolean(desktop && typeof window !== 'undefined' && window?.innerWidth && window.innerWidth > 1024),
		);
	}, [desktop]);

	const displayAdsOn: boolean = props?.displayAd ?? false;
	const recentlyPlayed: boolean =
		props?.recentlyPlayed && !displayAdsOn && LocalStorageService.getItem('recentlyPlayed') ? true : false;
	return (
		<Splide
			options={mergedOptions}
			className={'carousel__slider promo-carousel'}
			hasTrack={false}
			role='navigation'
			ref={splideRef}
		>
			<SplideTrack>{slides}</SplideTrack>
			{onePlusSlides && (
				<button className='splide__toggle' type='button' tabIndex={ETabIndexesOrderingLevels.AUTO}>
					<ButtonPlay
						className='splide__toggle__play'
						onClick={(e: SyntheticEvent) => {
							const splideEl = splideRef?.current as Splide;
							const splide = splideEl?.splide;
							// rewind to the first slide if not looped by settings
							if (
								splide &&
								!isLoop &&
								splide?.Components?.Controller?.getIndex?.() === splideEl?.slides?.length - 1
							) {
								splide?.Components?.Controller?.go?.(0);
								splide?.Components?.Autoplay?.play?.();
							}
						}}
					/>
					<ButtonPause className='splide__toggle__pause' />
				</button>
			)}
			{isDesktopSize && recentlyPlayed && (
				<RecentlyPlayed arenaData={arenaData} arenaBadges={arenaData?.arena_badges} />
			)}
		</Splide>
	);
};

function SetPromoCard(props: {
	card: PromoSlide;
	attributes: CardPromoAttributes;
	handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
}) {
	const { card, attributes, handleClick } = props;

	const promoCard: Promo = card.promos_library;
	const promoCardAttributes: CardPromoAttributes = attributes;
	const ariaTitle =
		promoCard?.title && promoCard?.button
			? `${promoCard?.button?.label}, ${promoCard?.title}`
			: `${promoCard?.title}`;

	return (
		<div className={`promo-card_wrapper`}>
			<div className='promo-card_card'>
				<LocalizedLink
					key={promoCard.button?.url}
					href={promoCard.button?.url}
					className={UIKIT_CONSTS.nextjsLinkWrapperClassname + ' promo-card_card_link'}
					target={'_self'}
					onClick={handleClick}
					aria-label={ariaTitle}
				>
					<div className='ark-ui-block-card-promo'>
						<PromoCard {...promoCardAttributes} />
					</div>
				</LocalizedLink>
			</div>
		</div>
	);
}

function NavStripesFx(splideRef: any) {
	// this is for later decision
	const navStripes =
		typeof document !== 'undefined'
			? Array.from(document?.body?.querySelectorAll('.splide__pagination__page') || [])
			: [];
	useEffect(() => {
		navStripes &&
			navStripes.length &&
			navStripes?.forEach((stripe: any, index: number) => {
				const clonedStripe = stripe.cloneNode(true);
				// make it tabindex mutable
				Object.defineProperty(clonedStripe, 'tabindex', {
					writable: true,
					configurable: true,
				});
				// setting own tabindex
				const tabindex = ETabIndexesOrderingLevels.AUTO;
				clonedStripe.tabindex = tabindex;
				clonedStripe?.setAttribute?.('tabindex', tabindex);
				clonedStripe.onclick = () => (splideRef as any)?.Components?.Controller.go(index); // can't refer to cloned one for traversing yet, not mounted
				// make it tabindex immutable for Splider lib
				Object.defineProperty(clonedStripe, 'tabindex', {
					writable: false,
					configurable: false,
				});
				stripe?.parentNode?.replaceChild?.(clonedStripe, stripe);
			});
	}, [navStripes]);
}
