'use client';
import { Slider } from './Slider';
import { SplideOptions, TCarousel } from './model';
import { LocalizedLink } from '@/components/Link';
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { UiKitRenderStatic } from '@/components/UiKit/UiKitRender';
import { renderMarkup } from '@/uikit/components/ark-ui-atom-button-calltoaction/_index';
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import styles from './styles.module.css';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { AnalyticsContext } from '@/components/Layout';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';

export const Carousel: React.FC<TCarousel> = (props) => {
	const title: string = props.title ?? '';
	const carouselAnalytics: string | null = props?.analyticId ?? null;
	const { href, label } = props.link ?? {};
	const target = props?.link?.target || '_self';
	const { AITracks } = useContext(AnalyticsContext);
	const arenaData = useUnit($arenaDataStore);
	const arenaAvailableCardBadges = arenaData?.arena_badges || [];

	const sliderOptions = useMemo(() => {
		const options: SplideOptions = {
			type: props.infiniteLoop ? 'loop' : 'slide',
		};
		return options;
	}, []);

	const linkComponent = useMemo(() => {
		if (href && label) {
			return (
				<LocalizedLink
					prefetch='default'
					href={href}
					target={target}
					tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
					onClick={() =>
						AITracks.genericTrack({
							eventName: 'seeAllButton',
							isNonInteraction: false,
							eventAction: AnalyticsEventAction.CLICK,
							customDimensions: {
								carouselId: props.id,
								carouselTitle: title,
							},
						})
					}
				>
					<UiKitRenderStatic
						tagName={'ark-ui-atom-button-calltoaction'}
						innerHTML={renderMarkup({
							text: label,
							size: 'small',
							styling: 'text',
							tabIndex: 'auto',
						})}
					/>
				</LocalizedLink>
			);
		}
		return null;
	}, [href, label, target, AITracks, props.id, title]);

	const sliderComponent = useMemo(() => {
		return (
			<Slider
				key={JSON.stringify(sliderOptions)}
				slidesList={props.cards}
				analyticId={carouselAnalytics}
				sliderOptions={sliderOptions}
				infiniteLoop={props.infiniteLoop}
				badgesList={arenaAvailableCardBadges}
			/>
		);
	}, [props.cards, carouselAnalytics, sliderOptions]);

	if (!props.cards.length) {
		return null;
	}

	const isTopCarousel = props?.index === 0 ? ' top-carousel' : '';

	return (
		<section data-carousel-type='carousel' data-carousel-id={props.id} className={'carousel ' + isTopCarousel}>
			<div className='carousel_title'>
				{title && <p className='carousel_name'>{title}</p>}
				{linkComponent}
			</div>
			{sliderComponent}
		</section>
	);
};

const DynamicCarousel = dynamic(() => import('@/features/carousel').then((mod) => mod.Carousel), {
	ssr: false,
	loading: () => <div className={styles.loadingCarousel}>Loading...</div>,
});

export const ClientSideCarousel: React.FC<{ carousel: TCarousel }> = ({ carousel }) => {
	const [rootMargin, setRootMargin] = useState('200px');
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin,
		threshold: 0,
	});

	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 1024px)');

		const handleMediaQueryChange = (e: MediaQueryListEvent) => {
			setRootMargin(e.matches ? '400px' : '200px');
		};

		setRootMargin(mediaQuery.matches ? '400px' : '200px');

		mediaQuery.addEventListener('change', handleMediaQueryChange);

		return () => {
			mediaQuery.removeEventListener('change', handleMediaQueryChange);
		};
	}, []);

	useEffect(() => {
		if (inView) {
			setShouldRender(true);
		}
	}, [inView, carousel.id]);

	if (!carousel.cards.length) {
		return null;
	}

	return (
		<div ref={ref} className={`${styles.fadein} ${shouldRender ? styles.visible : ''}`}>
			{shouldRender && <DynamicCarousel {...carousel} />}
		</div>
	);
};
