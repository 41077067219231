'use client';

import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { Slider } from './Slider';
import { TCarouselPromo } from './model';
import { AdOptions, AdTypes, getDimensions } from '@/features/ad/model';
import Ad from '@/features/ad/view';
import { $isSidebarHidden } from '@/app.model';
import { useUnit } from 'effector-react';
import classNames from 'classnames';
import { logger } from '@/shared/utils/logger';

const CarouselPromo = (props: TCarouselPromo) => {
	const { isDesktop, isNotPc } = useDeviceDetector();
	const title: string = props.title ?? '';
	const displayAdsOn: boolean = props?.displayAd ?? false;
	const isSidebarHidden = useUnit($isSidebarHidden);

	const displayAdsBasicOptions = {
		'product': 'arena',
		'id': props?.id.toString() ?? '',
		'dimensions': getDimensions([AdTypes.AD_300x250]) as AdOptions['dimensions'],
		'data-id': `promo-carousel-ad${props.index === 0 ? '-top' : props.index}`,
		'data-testid': 'display-ad',
	};

	if (!props?.slides?.length) {
		logger.log('No promos found for carousel');
		return null;
	}

	const havingNoPromoLibraries = props?.slides?.filter((slide) => !slide?.promos_library);
	if (havingNoPromoLibraries.length) {
		havingNoPromoLibraries.forEach((slide) => logger.log(`No promo-library found for slide ${slide}`));
		return null;
	}

	const isTopCarousel = props.index === 0;
	const containerVariation = !isTopCarousel ? 'container' : 'container';

	const sectionClass = classNames('carousel promo', {
		'__ads': displayAdsOn && isDesktop(),
		'top-carousel': isTopCarousel,
		'top-carousel-with-sidebar': !isSidebarHidden,
	});

	return (
		<section data-carousel-type='promo-carousel' data-carousel-id={props.id} className={sectionClass}>
			{!isTopCarousel && (
				<div className={'carousel_title'}>{title && <p className={'carousel_name'}>{title}</p>}</div>
			)}
			<div className={containerVariation}>
				<Slider {...props} />
				{displayAdsOn && isDesktop() && !isNotPc() && (
					<div className='promo-card_ad m-l-1'>
						<Ad adOptions={displayAdsBasicOptions} />
					</div>
				)}
			</div>
		</section>
	);
};

export default CarouselPromo;
